import { guid } from '@/api/cookie'
import VueI18n from 'vue-i18n'

const i18n = new VueI18n({
  locale: window.sessionStorage.getItem('lang'), //
  messages: {
    'zh-CN': require('@/common/lang/zh'),
    'en-US': require('@/common/lang/en')
  }
})

export function initMap (mapInstance, center, zoom) { // 初始化地图
  switch (mapInstance.mapType) {
    case 'baidu':
      if (center === undefined || center.lat === undefined || center.lng === undefined) {
        center = {
          lat: 22.5711440000,
          lng: 113.8687300000
        }
      }
      initBaidu(mapInstance, center, zoom)
      break
    case 'google':
      if (center === undefined || center.lat === undefined || center.lng === undefined) {
        center = {
          lat:25.037798, 
          lng:121.565170
        }
      }
      initGoogle(mapInstance, center, zoom)
      break
    default:
  }
}

function initBaidu (mapInstance, center, zoom) { // 初始化百度地图
  mapInstance.zoom = zoom
  // let mapType1 = mapInstance.bMap.MapTypeControl({
  //   mapTypes: [ window.BMAP_NORMAL_MAP, window.BMAP_HYBRID_MAP ]
  // })
  // let mapType2 = mapInstance.bMap.MapTypeControl({
  //   anchor: window.BMAP_ANCHOR_TOP_LEFT
  // })
  // let overView = mapInstance.bMap.OverviewMapControl()
  // let overViewOpen = mapInstance.bMap.OverviewMapControl({
  //   isOpen: true,
  //   anchor: window.BMAP_ANCHOR_BOTTOM_RIGHT
  // })
  // mapInstance.map.addControl(mapType1)
  // mapInstance.map.addControl(mapType2)
  // mapInstance.map.setCurrentCity('����')
  // mapInstance.map.addControl(overView)
  // mapInstance.map.addControl(overViewOpen)
  mapInstance.map.addEventListener('click', function (e) {
    mapInstance.currentPoint = {
      lng: e.point.lng,
      lat: e.point.lat
    }
    if (mapInstance.clickFunc !== undefined) {
      mapInstance.clickFunc(mapInstance.currentPoint)
    }
  })
  mapInstance.map.addEventListener('zoomend', function (e) {
    mapInstance.zoom = mapInstance.map.getZoom()
  })
  baiduMoveToCenter(mapInstance, center)
}

function initGoogle (mapInstance, center, zoom) { // 初始化谷歌地图
  mapInstance.zoom = zoom
  // googleMoveToCenter(mapInstance, center)
  mapInstance.googleMaps.event.addListener(mapInstance.map, 'click', function (e) {
    mapInstance.currentPoint = {
      lng: e.latLng.lng(),
      lat: e.latLng.lat()
    }
    if (mapInstance.clickFunc !== undefined) {
      mapInstance.clickFunc(mapInstance.currentPoint)
    }
  })
  googleMoveToCenter(mapInstance, center)
}

export function makeMarker (mapInstance, data) { // 生成Marker,传入deviceMoel数据
  let mark
  switch (mapInstance.mapType) {
    case 'baidu':
      mark = baiduMarkerAndInfoWindow(mapInstance, data)
      break
    case 'google':
      mark = googleMarkerAndInfoWindow(mapInstance, data)
      break
    default:
  }
  if (mark !== null) {
    mapInstance.markList.push(mark)
  }

  return mapInstance
}

export function moveFirstPoint (mapInstance) { // 跳转到第一个marker的位置
  if (mapInstance.markList.length !== 0) {
    let mark = mapInstance.markList[0]
    moveToCenter(mapInstance, mark.point)
  }
}

export function batchMakeMarker (mapInstance, list) { // 批量生成marker,传入list<deviceModel>列表
  mapInstance = cleanMarks(mapInstance)

  for (let i = 0; i < list.length; i++) {
    mapInstance = makeMarker(mapInstance, list[i])
  }
  return mapInstance
}

export function cleanMarks (mapInstance) { // 清除并删除地图上的Marker
  switch (mapInstance.mapType) {
    case 'baidu':
      mapInstance.map.closeInfoWindow()
      for (let j = 0; j < mapInstance.markList.length; j++) {
        mapInstance.map.removeOverlay(mapInstance.markList[j].marker)
      }
      break
    case 'google':
      for (let j = 0; j < mapInstance.markList.length; j++) {
        mapInstance.markList[j].infoWindow.close()
        mapInstance.markList[j].marker.setMap(null)
      }
      break
    default:
  }
  mapInstance.markList = []
  mapInstance.currentOpenInfoWindow = undefined
  return mapInstance
}

export function openInfoWindowByDeviceImei (mapInstance, deviceImei) { // 根据设备号弹出相关marker的信息弹窗
  switch (mapInstance.mapType) {
    case 'baidu':
      openBaiduInfoWindow(mapInstance, deviceImei)
      break
    case 'google':
      openGoogleInfoWindow(mapInstance, deviceImei)
      break
    default:
  }
}

export function moveToCenter (mapInstance, center) { // 移动地图中心到指定位置
  switch (mapInstance.mapType) {
    case 'baidu':
      baiduMoveToCenter(mapInstance, center)
      break
    case 'google':
      googleMoveToCenter(mapInstance, center)
      break
    default:
  }
}

export function closeCurrentInfoWindow (mapInstance, deviceImei) { // 关闭当前打开的信息弹窗
  switch (mapInstance.mapType) {
    case 'baidu':
      closeBaiduCurrentInfoWindow(mapInstance, deviceImei)
      break
    case 'google':
      closeGoogleCurrentInfoWindow(mapInstance, deviceImei)
      break
    default:
  }
}

export function makePath (mapInstance, devicePath) { // 生成轨迹线

  switch (mapInstance.mapType) {
    case 'baidu':
      return makeBaiduPath(mapInstance, devicePath)
    case 'google':
      return makeGooglePath(mapInstance, devicePath)
    default:
  }
}


export function makeColorPath (mapInstance, devicePath, color) {  // 自定义颜色轨迹线
  switch (mapInstance.mapType) {
    case 'baidu':
      return makeBaiduColorPath(mapInstance, devicePath, color)
    case 'google':
      return makeGoogleColorPath(mapInstance, devicePath, color)
    default:
  }
}

export function cleanCurrentPath (mapInstance) { // 清除当前轨迹线
  switch (mapInstance.mapType) {
    case 'baidu':
      cleanCurrentBaiduPath(mapInstance)
      break
    case 'google':
      cleanCurrentGooglePath(mapInstance)
      break
    default:
  }
}

export function cleanAllPath (mapInstance) { // 清除所有轨迹线
  switch (mapInstance.mapType) {
    case 'baidu':
      cleanBaiduAllPath(mapInstance)
      break
    case 'google':
      cleanGoogleAllPath(mapInstance)
      break
    default:
  }
}

export function makeTrackMarker (mapInstance, track, position) { // 轨迹点标记

  switch (mapInstance.mapType) {
    case 'baidu':
      return makeBaiduTrackMarker(mapInstance, track, position)
    case 'google':
      return makeGoogleTrackMarker(mapInstance, track, position)
    default:
  }
}


export function addCircle (mapInstance, point, radius) { // 添加圆形覆盖物

  switch (mapInstance.mapType) {
    case 'baidu':
      addBaiduCircle(mapInstance, point, radius)
      break
    case 'google':
      addGoogleCircle(mapInstance, point, radius)
      break
    default:
  }
}

export function cleanAllCircle (mapInstance) { // 清除所有圆形覆盖物
  switch (mapInstance.mapType) {
    case 'baidu':
      cleanAllBaiduCircle(mapInstance)
      break
    case 'google':
      cleanAllGoogleCircle(mapInstance)
      break
    default:
  }
}

export function makeCircleMark (mapInstance, data) { // 生成圆形标记

  let mark
  switch (mapInstance.mapType) {
    case 'baidu':
      mark = makeBaiduCircleMark(mapInstance, data)
      break
    case 'google':
      mark = makeGoogleCircleMark(mapInstance, data)
      break
    default:
  }
  if (mark !== null) {
    mapInstance.circleMarkList.push(mark)
  }

  return mapInstance
}

export function batchMakeCircleMarker (mapInstance, list) { // 批量生成圆形标记

  mapInstance = cleanAllCircleMark(mapInstance)

  for (let i = 0; i < list.length; i++) {
    mapInstance = makeCircleMark(mapInstance, list[i])
  }
  return mapInstance
}

export function cleanAllCircleMark (mapInstance) { // 清除所有圆形标记

  switch (mapInstance.mapType) {
    case 'baidu':
      mapInstance.map.closeInfoWindow()
      for (let j = 0; j < mapInstance.circleMarkList.length; j++) {
        mapInstance.map.removeOverlay(mapInstance.circleMarkList[j].marker)
      }
      break
    case 'google':
      for (let j = 0; j < mapInstance.circleMarkList.length; j++) {
        mapInstance.circleMarkList[j].infoWindow.close()
        mapInstance.circleMarkList[j].marker.setMap(null)
      }
      break
    default:
  }
  mapInstance.circleMarkList = []
  return mapInstance
}

function inOutFormatter (inOut) {
  if (inOut === 1) {
    return 'For In'
  } else if (inOut === 2) {
    return 'For Out'
  } else {
    return 'For In/Out'
  }
}

function makeBaiduCircleMark (mapInstance, data) {
  function getMark () {
    if (data === null || data === undefined) {
      return null
    }
    if (data.longitude === null || data.latitude === null) {
      return null
    }
    let lng = data.longitude
    let lat = data.latitude
    let point = new mapInstance.bMap.Point(lng, lat)
    let marker = new mapInstance.bMap.Marker(point)
    mapInstance.map.addOverlay(marker)
    let inOut = inOutFormatter(data.inOut)
    i18n.locale = window.sessionStorage.getItem('lang')
    let content = '<table>'
    content = content + '<tr><td> ' + i18n.t('mapJs.fenceName') + ':      ' + data.fenceName + '</td></tr>'
    content = content + '<tr><td> ' + i18n.t('mapJs.radius') + ':         ' + data.radius + '</td></tr>'
    content = content + '<tr><td> ' + i18n.t('mapJs.inOut') + ':       ' + inOut + '</td></tr>'
    content += '</table>'

    let infoWindow = new mapInstance.bMap.InfoWindow(content)

    marker.addEventListener('click', () => {
      mapInstance.map.openInfoWindow(infoWindow, point)
      mapInstance.map.centerAndZoom(point, mapInstance.zoom)
    })
    let mark = {
      marker: marker,
      point: point,
      infoWindow: infoWindow,
      fenceId: data.fenceId,
      lng: lng,
      lat: lat
    }
    return mark
  }

  return getMark()
}

function makeGoogleCircleMark (mapInstance, data) {
  function getMark () {
    if (data === null || data === undefined) {
      return null
    }
    if (data.longitude === null || data.latitude === null) {
      return null
    }
    let lng = data.longitude
    let lat = data.latitude
    let markerOption = {
      position: { lat: lat, lng: lng },
      map: mapInstance.map
    }
    let marker = new mapInstance.googleMaps.Marker(markerOption)
    let inOut = inOutFormatter(data.inOut)
    i18n.locale = window.sessionStorage.getItem('lang')
    let content = '<table>'
    content = content + '<tr><td> ' + i18n.t('mapJs.fenceName') + ':    ' + data.fenceName + '</td></tr>'
    content = content + '<tr><td> ' + i18n.t('mapJs.radius') + ':    ' + data.radius + '</td></tr>'
    content = content + '<tr><td> ' + i18n.t('mapJs.inOut') + ':    ' + inOut + '</td></tr>'
    content += '</table>'

    let infoWindow = new mapInstance.googleMaps.InfoWindow({
      content: content
    })

    marker.addListener('click', () => {
      infoWindow.open({
        anchor: marker,
        map: mapInstance.map,
        shouldFocus: true
      })
      mapInstance.map.panTo({ lat: lat, lng: lng })
    })

    let mark = {
      marker: marker,
      point: { lat: lat, lng: lng },
      infoWindow: infoWindow,
      fenceId: data.fenceId,
      lng: lng,
      lat: lat
    }
    return mark
  }

  return getMark()
}

function cleanAllBaiduCircle (mapInstance) {
  for (let i = 0; i < mapInstance.circleList.length; i++) {
    mapInstance.map.removeOverlay(mapInstance.circleList[i])
  }
  mapInstance.circleList = []
}

function cleanAllGoogleCircle (mapInstance) {
  for (let i = 0; i < mapInstance.circleList.length; i++) {
    mapInstance.circleList[i].circle.setMap(null)
  }
  mapInstance.circleList = []
}

function addBaiduCircle (mapInstance, point, radius) {
  let opt = {
    strokeColor: 'blue',
    strokeWeight: 2,
    strokeOpacity: 0.5
  }
  let p = new mapInstance.bMap.Point(point.lng, point.lat)
  let circle = new mapInstance.bMap.Circle(p, radius, opt)
  mapInstance.map.addOverlay(circle)
  // circle.show()
  // circle.draw()
  // console.log(circle.isVisible())
  mapInstance.circleList.push(circle)
}

function addGoogleCircle (mapInstance, point, radius) {
  const p = {
    lat: point.lat,
    lng: point.lng
  }
  let circle = new mapInstance.googleMaps.Circle({
    strokeColor: '#0022ff',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#d4d3b2',
    fillOpacity: 0.35,
    map: mapInstance.map,
    center: p,
    radius: radius
  })
  mapInstance.circleList.push(circle)
}

function cleanCurrentBaiduPath (mapInstance) {
  if (mapInstance.currentPoly) {
    let polyList = []
    for (let i = 0; i < mapInstance.polyList.length; i++) {
      if (mapInstance.polyList[i].id !== mapInstance.currentPoly.id) {
        polyList.push(mapInstance.polyList[i])
      }
    }
    mapInstance.polyList = polyList
    mapInstance.map.removeOverlay(mapInstance.currentPoly.polyline)
    mapInstance.currentPoly = undefined
  }
}

function cleanCurrentGooglePath (mapInstance) {
  if (mapInstance.currentPoly) {
    let polyList = []
    for (let i = 0; i < mapInstance.polyList.length; i++) {
      if (mapInstance.polyList[i].id !== mapInstance.currentPoly.id) {
        polyList.push(mapInstance.polyList[i])
      }
    }
    mapInstance.polyList = polyList
    mapInstance.currentPoly.polyline.setMap(null)
    mapInstance.currentPoly = undefined
  }
}

function cleanBaiduAllPath (mapInstance) {
  for (let i = 0; i < mapInstance.polyList.length; i++) {
    mapInstance.map.removeOverlay(mapInstance.polyList[i].polyline)
  }
  mapInstance.polyList = []
  mapInstance.currentPoly = undefined
}

function cleanGoogleAllPath (mapInstance) {
  for (let i = 0; i < mapInstance.polyList.length; i++) {
    mapInstance.polyList[i].polyline.setMap(null)
  }
  mapInstance.polyList = []
  mapInstance.currentPoly = undefined
}

function makeBaiduPath (mapInstance, devicePath) {
  let points = []
  for (let i = 0; i < devicePath.length; i++) {
    let point = new mapInstance.bMap.Point(devicePath[i].lng, devicePath[i].lat)
    points.push(point)
  }
  let polyline = new mapInstance.bMap.Polyline(
    points, { strokeColor: 'blue', strokeWeight: 2, strokeOpacity: 0.5 }
  )
  mapInstance.map.addOverlay(polyline)
  let p = {
    id: guid(),
    polyline: polyline
  }
  mapInstance.polyList.push(p)
  mapInstance.currentPoly = p
  return polyline
}

function makeBaiduColorPath (mapInstance, devicePath, color) {
  let points = []
  for (let i = 0; i < devicePath.length; i++) {
    let point = new mapInstance.bMap.Point(devicePath[i].lng, devicePath[i].lat)
    points.push(point)
  }
  let polyline = new mapInstance.bMap.Polyline(
    points, { strokeColor: color, strokeWeight: 2, strokeOpacity: 0.5 }
  )
  mapInstance.map.addOverlay(polyline)
  let p = {
    id: guid(),
    polyline: polyline
  }
  mapInstance.polyList.push(p)
  mapInstance.currentPoly = p
  return polyline
}

function makeGooglePath (mapInstance, devicePath) {
  let points = []
  for (let i = 0; i < devicePath.length; i++) {
    let point = {
      lat: devicePath[i].lat,
      lng: devicePath[i].lng
    }
    points.push(point)
  }
  let polyline = new mapInstance.googleMaps.Polyline({
    path: points,
    strokeColor: 'blue',
    strokeWeight: 2,
    strokeOpacity: 1
  })
  polyline.setMap(mapInstance.map)
  let p = {
    id: guid(),
    polyline: polyline
  }
  mapInstance.polyList.push(p)
  mapInstance.currentPoly = p
  return polyline
}

function makeGoogleColorPath (mapInstance, devicePath, color) { 

  let points = []
  for (let i = 0; i < devicePath.length; i++) {
    let point = {
      lat: devicePath[i].lat,
      lng: devicePath[i].lng
    }
    points.push(point)
  }
  let polyline = new mapInstance.googleMaps.Polyline({
    path: points,
    strokeColor: color,
    strokeWeight: 2,
    strokeOpacity: 1
  })
  polyline.setMap(mapInstance.map)
  let p = {
    id: guid(),
    polyline: polyline
  }
  mapInstance.polyList.push(p)
  mapInstance.currentPoly = p
  return polyline
}

function closeBaiduCurrentInfoWindow (mapInstance, deviceImei) {
  if (mapInstance.currentOpenInfoWindow) {
    if (mapInstance.currentOpenInfoWindow.deviceImei === deviceImei) {
      mapInstance.map.closeInfoWindow()
      mapInstance.currentOpenInfoWindow = null
    }
  }
}

function closeGoogleCurrentInfoWindow (mapInstance, deviceImei) {
  if (mapInstance.currentOpenInfoWindow) {
    if (mapInstance.currentOpenInfoWindow.deviceImei === deviceImei) {
      mapInstance.currentOpenInfoWindow.infoWindow.close()
      mapInstance.currentOpenInfoWindow = null
    }
  }
}

function openBaiduInfoWindow (mapInstance, deviceImei) {
  for (let i = 0; i < mapInstance.markList.length; i++) {
    if (mapInstance.markList[i].deviceImei === deviceImei) {
      mapInstance.map.openInfoWindow(mapInstance.markList[i].infoWindow, mapInstance.markList[i].point)
      mapInstance.currentOpenInfoWindow = {
        marker: mapInstance.markList[i].marker,
        infoWindow: mapInstance.markList[i].infoWindow,
        point: mapInstance.markList[i].marker.getPosition(),
        deviceImei: deviceImei
      }
      let point = {
        lat: mapInstance.markList[i].lat,
        lng: mapInstance.markList[i].lng
      }
      moveToCenter(mapInstance, point)
      return mapInstance
    }
  }
  return mapInstance
}

function openGoogleInfoWindow (mapInstance, deviceImei) {
  if (mapInstance.currentOpenInfoWindow !== undefined && mapInstance.currentOpenInfoWindow !== null) {
    mapInstance.currentOpenInfoWindow.infoWindow.close()
  }

  for (let i = 0; i < mapInstance.markList.length; i++) {
    if (mapInstance.markList[i].deviceImei === deviceImei) {
      mapInstance.markList[i].infoWindow.open({
        anchor: mapInstance.markList[i].marker,
        map: mapInstance.map,
        shouldFocus: true
      })
      mapInstance.currentOpenInfoWindow = {
        marker: mapInstance.markList[i].marker,
        infoWindow: mapInstance.markList[i].infoWindow,
        point: mapInstance.markList[i].point,
        deviceImei: deviceImei
      }
      let point = {
        lat: mapInstance.markList[i].lat,
        lng: mapInstance.markList[i].lng
      }
      moveToCenter(mapInstance, point)
      return mapInstance
    }
  }
  return mapInstance
}

function baiduMoveToCenter (mapInstance, center) {
  let point = new mapInstance.bMap.Point(center.lng, center.lat)
  if (mapInstance.zoom) {
    mapInstance.map.centerAndZoom(point, mapInstance.zoom)
  } else {
    mapInstance.map.panTo(point)
  }
}

function googleMoveToCenter (mapInstance, center) {
  mapInstance.mapRef.$mapPromise.then((map) => {
    map.panTo({ lat: center.lat, lng: center.lng })
  })
}

function baiduMarkerAndInfoWindow (mapInstance, data) { // 生成百度地图Marker并添加点击弹出信息弹窗事件,data(deviceModel)
  function getMark () {
    if (!data || !data.lastLongitude || !data.lastLatitude || (data.lastLatitude === 0 && data.lastLongitude === 0)) {
      return null
    }
    let lng = data.lastLongitude
    let lat = data.lastLatitude
    let point = new mapInstance.bMap.Point(lng, lat)
    // let icon = makeBaiduIcon(mapInstance, data)
    // let marker = new mapInstance.bMap.Marker(point, { icon: icon })
    let marker = new mapInstance.bMap.Marker(point)
    mapInstance.map.addOverlay(marker)
    let p = mapInstance.contentFunc(data, mapInstance.haveBtn)
    let infoContent = p()
    let infoWindow = new mapInstance.bMap.InfoWindow(infoContent)

    marker.addEventListener('click', () => {
      mapInstance.map.openInfoWindow(infoWindow, marker.getPosition())
      // console.log(marker.getPosition())
      mapInstance.map.centerAndZoom(point, mapInstance.zoom)
      mapInstance.currentOpenInfoWindow = {
        marker: marker,
        infoWindow: infoWindow,
        point: marker.getPosition(),
        deviceImei: data.deviceImei
      }
    })
    infoWindow.addEventListener('clickclose', () => {
      mapInstance.currentOpenInfoWindow = undefined
    })
    let mark = {
      marker: marker,
      point: point,
      infoWindow: infoWindow,
      deviceImei: data.deviceImei,
      lng: lng,
      lat: lat
    }
    return mark
  }

  return getMark()
}

// function makeBaiduIcon (mapInstance, data) {
//   let iconSize = new mapInstance.bMap.Size(64, 64)
//   let iconFile = getIconFile(data.heading, data)
//   let icon = new mapInstance.bMap.Icon(require('../assets/carIcon' + iconFile), iconSize, { imageSize: iconSize })
//   return icon
// }

function makeGoogleIcon (mapInstance, data) {
  return getIconFile(data.heading, data)
}

function getAngle (angle) {
  if (angle === '' || angle === undefined || angle === null) {
    return 0
  }
  if (!isNaN(angle)) {
    return 0
  }
  let x = 10 * angle + 225
  let y = Math.round(x / 450)
  if (y >= 8) {
    y = 0
  }
  return y * 45
}

function getIconFile (angle, data) {
  let fileName
  let a = getAngle(angle)
  if (data.onlineStatus) {
    if (data.lastMotionStatus === 1) {
      fileName = '/run_'
    } else {
      fileName = '/online_'
    }
  } else {
    fileName = '/offline_'
  }
  return fileName + a * 45 + '.png'
}

function googleMarkerAndInfoWindow (mapInstance, data) {
  function getMark () {
    if (data === null || data === undefined) {
      return null
    }
    if (data.lastLongitude === null || data.lastLatitude === null) {
      return null
    }
    let icon = makeGoogleIcon(mapInstance, data)
    let markerOption = {
      position: { lat: data.lastLatitude, lng: data.lastLongitude },
      map: mapInstance.map,
      // icon: require('../assets/carIcon' + icon)
    }
    let marker = new mapInstance.googleMaps.Marker(markerOption)
    let p = mapInstance.contentFunc(data, mapInstance.haveBtn)
    let infoContent = p()
    let infoWindow = new mapInstance.googleMaps.InfoWindow({
      content: infoContent
    })
    marker.addListener('click', () => {
      for (let i = 0; i < mapInstance.markList.length; i++) {
        mapInstance.markList[i].infoWindow.close()
      }
      infoWindow.open({
        anchor: marker,
        map: mapInstance.map,
        shouldFocus: true
      })
      mapInstance.map.panTo({ lat: data.lastLatitude, lng: data.lastLongitude })
      mapInstance.currentOpenInfoWindow = {
        marker: marker,
        infoWindow: infoWindow,
        point: { lat: data.lastLatitude, lng: data.lastLongitude },
        deviceImei: data.deviceImei
      }
    })
    infoWindow.addListener('closeclick', () => {
      mapInstance.currentOpenInfoWindow = undefined
    })
    let mark = {
      marker: marker,
      point: { lat: data.lastLatitude, lng: data.lastLongitude },
      infoWindow: infoWindow,
      deviceImei: data.deviceImei,
      lng: data.lastLongitude,
      lat: data.lastLatitude
    }
    return mark
  }

  return getMark()
}



function makeBaiduTrackMarker (mapInstance, track, position) { // 轨迹点标记

  let icon
  let iconSize = new mapInstance.bMap.Size(10, 10)
  let anchor = new mapInstance.bMap.Size(5, 5)
  if (position === 'start') {
    icon = require('@/assets/iconsOfMap/startMarker.png')
    iconSize = new mapInstance.bMap.Size(24, 32)
    anchor = new mapInstance.bMap.Size(12, 32)
  } else if (position === 'subordinate') {
    icon = require('@/assets/iconsOfMap/sub_Marker.png')
  } else if (position === 'supplement') {
    icon = require('@/assets/iconsOfMap/sup_Marker.png')
  } else if (position === 'end') {
    icon = require('@/assets/iconsOfMap/endMarker.png')
    iconSize = new mapInstance.bMap.Size(24, 32)
    anchor = new mapInstance.bMap.Size(12, 32)
  }
  const latitude = track.lat
  const longitude = track.lng
  const point = new mapInstance.bMap.Point(longitude, latitude)
  var myIcon = new mapInstance.bMap.Icon(icon, iconSize, {
    anchor: anchor
  })
  const marker = new mapInstance.bMap.Marker(point, { icon: myIcon })
  mapInstance.markList.push(marker)
  mapInstance.map.addOverlay(marker)
  let p = mapInstance.contentFunc(track, mapInstance.haveBtn)
  let infoContent = p()
  let infoWindow = new mapInstance.bMap.InfoWindow(infoContent)
  marker.addEventListener('click', () => {
    mapInstance.map.openInfoWindow(infoWindow, point)
    mapInstance.map.centerAndZoom(point, mapInstance.zoom)
    mapInstance.currentOpenInfoWindow = {
      marker: marker,
      infoWindow: infoWindow,
      point: point,
      deviceImei: track.deviceImei
    }
  })
  infoWindow.addEventListener('clickclose', () => {
    mapInstance.currentOpenInfoWindow = undefined
  })
  return marker
}

function makeGoogleTrackMarker (mapInstance, track, position) {
  let icon
  let iconSize = new mapInstance.googleMaps.Size(10, 10)
  let anchor = new mapInstance.googleMaps.Size(5, 5)
  if (position === 'start') {
    icon = require('@/assets/iconsOfMap/startMarker.png')
    iconSize = new mapInstance.googleMaps.Size(24, 32)
    anchor = new mapInstance.googleMaps.Size(12, 32)
  } else if (position === 'subordinate') {
    icon = require('@/assets/iconsOfMap/sub_Marker.png')
  } else if (position === 'supplement') {
    icon = require('@/assets/iconsOfMap/sup_Marker.png')
  } else if (position === 'end') {
    icon = require('@/assets/iconsOfMap/endMarker.png')
    iconSize = new mapInstance.googleMaps.Size(24, 32)
    anchor = new mapInstance.googleMaps.Size(12, 32)
  }
  const latitude = track.lat
  const longitude = track.lng
  // const point = new mapInstance.googleMaps.Point(longitude, latitude)
  // var myIcon = new mapInstance.googleMaps.Icon(icon, iconSize, {
  //   anchor: anchor
  // })
  let markerOption = {
    position: { lat: latitude, lng: longitude },
    map: mapInstance.map,
    icon: icon,
    anchor: anchor
  }
  const marker = new mapInstance.googleMaps.Marker(markerOption)
  mapInstance.markList.push(marker)
  // const marker = new mapInstance.googleMaps.Marker(point, { icon: myIcon })
  // mapInstance.markList.push(marker)
  // mapInstance.map.addOverlay(marker)
  let p = mapInstance.contentFunc(track, mapInstance.haveBtn)
  let infoContent = p()
  let infoWindow = new mapInstance.googleMaps.InfoWindow({
    content: infoContent
  })
  marker.infoWindow = infoWindow
  marker.addListener('click', () => {
    // if(mapInstance.markList) {
    //   for (let i = 0; i < mapInstance.markList.length; i++) {
    //     mapInstance.markList[i].infoWindow.close()
    //   }
    // }
    if(mapInstance.currentOpenInfoWindow) {
      mapInstance.currentOpenInfoWindow.infoWindow.close()
    }
    infoWindow.open({
      anchor: marker,
      map: mapInstance.map,
      shouldFocus: true
    })
    mapInstance.map.panTo({ lat: latitude, lng: longitude })
    mapInstance.currentOpenInfoWindow = {
      marker: marker,
      infoWindow: infoWindow,
      point: { lat: latitude, lng: longitude },
      deviceImei: track.deviceImei
    }
  })
  infoWindow.addListener('closeclick', () => {
    mapInstance.currentOpenInfoWindow = undefined
  })
  return marker

}

/**
 * 更新marker的位置和弹出窗内容
 * @param {*} mapInstance
 * @param {Device} data
 */
export function changeMarkerPositionAndUpdateInfoWindow (mapInstance, data) {
  if (!mapInstance.markList) return
  mapInstance.markList.forEach(item => {
    if (item.deviceImei === data.deviceImei) {
      const lat = data.lastLatitude
      const lng = data.lastLongitude
      if (mapInstance.mapType === 'baidu') {
        item.marker.setPosition(new mapInstance.bMap.Point(lng, lat))
        item.point = new mapInstance.bMap.Point(lng, lat)
        let p = mapInstance.contentFunc(data, mapInstance.haveBtn)
        let infoContent = p()
        item.infoWindow.setContent(infoContent)
        // console.log('positionHasBeenChanged')
      } else if (mapInstance.mapType === 'google') {
        item.marker.setPosition(new mapInstance.googleMaps.LatLng(lat, lng))
      }
    }
  })
}

export function convertTrackToDevice (track) { // 将轨迹对象转换为设备对象 track => device
  let deviceModel = {}
  deviceModel.deviceImei = track.deviceImei // 国际移动设备识别码
  deviceModel.deviceType = track.deviceType // 设备类型 1、车载定位器  2、鸽子定位器 3、学生卡
  deviceModel.deviceName = track.deviceName // 设备昵称
  deviceModel.lastDeviceVol = track.deviceVol // 设备剩余电量
  deviceModel.lastLongitude = track.lng // 经度
  deviceModel.lastLatitude = track.lat // 经度
  deviceModel.heading = track.heading // 设备朝向 degree
  deviceModel.lastGpsTime = track.gpsTime // 定位时间
  deviceModel.lastLocationTime = track.logTime // 定位上传时间
  deviceModel.onlineStatus = track.onlineStatus // 在线状态
  deviceModel.lastLocationType = track.locationType // 定位类型
  deviceModel.weather = track.weather // 天气状况
  deviceModel.lastPositionDesc = track.positionDesc // 地址描述
  deviceModel.engineStatus = track.accStatus // 车载 ACC开关
  deviceModel.lastMotionStatus = track.motionStatus // 车载 设备状态
  deviceModel.pointIndex = track.pointIndex // 当前比赛 顺序点(第几个点)
  deviceModel.deviceSms = track.deviceSms // 信号强度
  deviceModel.speed = track.speed // 速度
  deviceModel.altitude = track.altitude // 海拔高度
  deviceModel.accumulateDuration = track.accumulateDuration // 累积时长
  deviceModel.accumulateOdometer = track.accumulateOdometer // 累积里程
  deviceModel.duration = track.duration // 距上一点的时长
  deviceModel.odometer = track.odometer // 距上一点的距离
  deviceModel.version = track.version
  return deviceModel
}

// AI 新增方法

export function batchMakePathsAndMarkers(mapInstance, data) { // 批量生成轨迹线和标记点

  if (!mapInstance || !data) {
    console.error('Invalid parameters');
    return;
  }

  // 清除现有的轨迹线和标记点
  cleanAllPath(mapInstance);
  cleanMarks(mapInstance);

  // 遍历每条轨迹线数据
  data.forEach((pathData) => {
    const color = getRandomColor(); // 为每条轨迹线生成随机颜色

    // 生成轨迹线
    const polyline = makeColorPath(mapInstance, pathData, color);

    // 多条数据生成标记点
    pathData.forEach((pointData) => {
      const marker = addmakeTrackMarker(mapInstance, pointData, 'subordinate', color);
      if (marker) {
        mapInstance.markList.push(marker);
      }
    });



    // 将轨迹线添加到地图
    mapInstance.polyList.push({
      id: guid(),
      polyline: polyline,
      color: color
    });
  });
}

function addmakeTrackMarker2(mapInstance, track, position, color) {
  let icon;
  let iconSize;
  let anchor;

  if (mapInstance.mapType === 'baidu') {
    iconSize = new mapInstance.bMap.Size(10, 10);
    anchor = new mapInstance.bMap.Size(5, 5);
    icon = getBaiduIcon(color);
  } else if (mapInstance.mapType === 'google') {
    iconSize = new mapInstance.googleMaps.Size(10, 10);
    anchor = new mapInstance.googleMaps.Size(5, 5);
    icon = getGoogleIcon(color);
  }

  const latitude = track.lat;
  const longitude = track.lng;

  if (mapInstance.mapType === 'baidu') {
    const point = new mapInstance.bMap.Point(longitude, latitude);
    const myIcon = new mapInstance.bMap.Icon(icon, iconSize, { anchor: anchor });
    const marker = new mapInstance.bMap.Marker(point, { icon: myIcon });
    mapInstance.map.addOverlay(marker);

    const infoContent = mapInstance.contentFunc(track, mapInstance.haveBtn)();
    const infoWindow = new mapInstance.bMap.InfoWindow(infoContent);

    marker.addEventListener('click', () => {
      mapInstance.map.openInfoWindow(infoWindow, point);
      mapInstance.map.centerAndZoom(point, mapInstance.zoom);
      mapInstance.currentOpenInfoWindow = {
        marker: marker,
        infoWindow: infoWindow,
        point: point,
        deviceImei: track.deviceImei
      };
    });

    infoWindow.addEventListener('clickclose', () => {
      mapInstance.currentOpenInfoWindow = undefined;
    });

    return marker;
  } else if (mapInstance.mapType === 'google') {
    const markerOption = {
      position: { lat: latitude, lng: longitude },
      map: mapInstance.map,
      icon: icon,
      anchor: anchor
    };
    const marker = new mapInstance.googleMaps.Marker(markerOption);

    const infoContent = mapInstance.contentFunc(track, mapInstance.haveBtn)();
    const infoWindow = new mapInstance.googleMaps.InfoWindow({ content: infoContent });

    marker.addListener('click', () => {
      if (mapInstance.currentOpenInfoWindow) {
        mapInstance.currentOpenInfoWindow.infoWindow.close();
      }
      infoWindow.open({
        anchor: marker,
        map: mapInstance.map,
        shouldFocus: true
      });
      mapInstance.map.panTo({ lat: latitude, lng: longitude });
      mapInstance.currentOpenInfoWindow = {
        marker: marker,
        infoWindow: infoWindow,
        point: { lat: latitude, lng: longitude },
        deviceImei: track.deviceImei
      };
    });

    infoWindow.addListener('closeclick', () => {
      mapInstance.currentOpenInfoWindow = undefined;
    });

    return marker;
  }
}


function addmakeTrackMarker(mapInstance, track, position, color) {
  const latitude = track.lat;
  const longitude = track.lng;

  if (mapInstance.mapType === 'baidu') {
    const point = new mapInstance.bMap.Point(longitude, latitude);
    const iconUrl = getSvgIcon(color); // 获取动态颜色的 SVG 图标
    const myIcon = new mapInstance.bMap.Icon(iconUrl, new mapInstance.bMap.Size(24, 24));
    const marker = new mapInstance.bMap.Marker(point, { icon: myIcon });
    mapInstance.map.addOverlay(marker);

    const infoContent = mapInstance.contentFunc(track, mapInstance.haveBtn)();
    const infoWindow = new mapInstance.bMap.InfoWindow(infoContent);

    marker.addEventListener('click', () => {
      mapInstance.map.openInfoWindow(infoWindow, point);
      mapInstance.map.centerAndZoom(point, mapInstance.zoom);
      mapInstance.currentOpenInfoWindow = {
        marker: marker,
        infoWindow: infoWindow,
        point: point,
        deviceImei: track.deviceImei
      };
    });

    infoWindow.addEventListener('clickclose', () => {
      mapInstance.currentOpenInfoWindow = undefined;
    });

    return marker;
  } else if (mapInstance.mapType === 'google') {
    const markerOption = {
      position: { lat: latitude, lng: longitude },
      map: mapInstance.map,
      icon: {
        url: getSvgIcon(color), // 获取动态颜色的 SVG 图标
        scaledSize: new mapInstance.googleMaps.Size(24, 24)
      }
    };
    const marker = new mapInstance.googleMaps.Marker(markerOption);

    const infoContent = mapInstance.contentFunc(track, mapInstance.haveBtn)();
    const infoWindow = new mapInstance.googleMaps.InfoWindow({ content: infoContent });

    marker.addListener('click', () => {
      if (mapInstance.currentOpenInfoWindow) {
        mapInstance.currentOpenInfoWindow.infoWindow.close();
      }
      infoWindow.open({
        anchor: marker,
        map: mapInstance.map,
        shouldFocus: true
      });
      mapInstance.map.panTo({ lat: latitude, lng: longitude });
      mapInstance.currentOpenInfoWindow = {
        marker: marker,
        infoWindow: infoWindow,
        point: { lat: latitude, lng: longitude },
        deviceImei: track.deviceImei
      };
    });

    infoWindow.addListener('closeclick', () => {
      mapInstance.currentOpenInfoWindow = undefined;
    });

    return marker;
  }
}


function getBaiduIcon(color) {
  // 根据颜色生成百度地图的图标
  // 这里可以根据需要自定义图标的样式
  return require(`@/assets/iconsOfMap/marker_${color}.png`);
}

function getGoogleIcon(color) {
  // 根据颜色生成谷歌地图的图标
  // 这里可以根据需要自定义图标的样式
  return require(`@/assets/iconsOfMap/marker_${color}.png`);
}


function getSvgIcon(color) {
  const svg = `
    <svg t="1741678547814" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1338" width="48" height="48">
    <path d="M831.9896 320.00816a319.996 319.996 0 0 1-280.956488 317.62803 7.9999 7.9999 0 0 0-7.039912 7.871901V991.99976a31.871602 31.871602 0 0 1-31.9996 31.9996 31.9996 31.9996 0 0 1-31.9996-31.9996v-346.36367a7.9999 7.9999 0 0 0-7.039912-7.935901A320.187998 320.187998 0 0 1 191.9976 321.032147 319.356008 319.356008 0 0 1 509.177635 0.01216 319.996 319.996 0 0 1 831.9896 320.00816z" fill="${color}" p-id="1339"></path></svg>
  `;
  return `data:image/svg+xml;base64,${btoa(svg)}`;
}

export function getRandomColor() {
  let color = '#'; // 使用 let 而不是 const
  const randomArr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
  for (let i = 0; i < 6; i++) {
    color += randomArr[Math.floor(Math.random() * 16)];
  }
  return color;
}

// AI 起点终点连线
export function drawStartEndMarkersAndPath(mapInstance, startPoint, endPoint) {
  if (!mapInstance ||!startPoint ||!endPoint) {
    console.error('Invalid parameters');
    return;
  }

  // 绘制起点和终点标记点
  const startMarker = drawMarker(mapInstance, startPoint, 'start');
  const endMarker = drawMarker(mapInstance, endPoint, 'end');

  // 绘制连接起点和终点的路径
  drawPath(mapInstance, [startPoint, endPoint]);

  return {
    startMarker,
    endMarker
  };
}

function drawMarker(mapInstance, point, position) {
  let icon;
  let iconSize;
  let anchor = new mapInstance.googleMaps.Size(5, 5)

  if (mapInstance.mapType === 'baidu') {
    if (position === 'start') {
      icon = require('@/assets/iconsOfMap/flagStart.png');
      iconSize = new mapInstance.bMap.Size(24, 32);
      anchor = new mapInstance.bMap.Size(12, 32);
    } else if (position === 'end') {
      icon = require('@/assets/iconsOfMap/flagEnd.png');
      iconSize = new mapInstance.bMap.Size(24, 32);
      anchor = new mapInstance.bMap.Size(12, 32);
    }
    const baiduPoint = new mapInstance.bMap.Point(point.lng, point.lat);
    const myIcon = new mapInstance.bMap.Icon(icon, iconSize, { anchor: anchor });
    const marker = new mapInstance.bMap.Marker(baiduPoint, { icon: myIcon });
    mapInstance.map.addOverlay(marker);

    const infoContent = getCustomInfoWindowContent(position);
    const infoWindow = new mapInstance.bMap.InfoWindow(infoContent);

    marker.addEventListener('click', () => {
      mapInstance.map.openInfoWindow(infoWindow, baiduPoint);
      mapInstance.map.centerAndZoom(baiduPoint, mapInstance.zoom);
      mapInstance.currentOpenInfoWindow = {
        marker: marker,
        infoWindow: infoWindow,
        point: baiduPoint,
        deviceImei: `marker_${position}`
      };
    });

    infoWindow.addEventListener('clickclose', () => {
      mapInstance.currentOpenInfoWindow = undefined;
    });

    return marker;
  } else if (mapInstance.mapType === 'google') {
    if (position === 'start') { 
      icon = require('@/assets/iconsOfMap/flagStart.png');
      iconSize = new mapInstance.googleMaps.Size(24, 32);
      anchor = new mapInstance.googleMaps.Size(12, 32);
    } else if (position === 'end') {
      icon = require('@/assets/iconsOfMap/flagEnd.png');
      iconSize = new mapInstance.googleMaps.Size(24, 32);
      anchor = new mapInstance.googleMaps.Size(12, 32);
    }
    const markerOption = {
      position: { lat: point.lat, lng: point.lng },
      map: mapInstance.map,
      icon: icon,
      anchor: anchor,
      iconSize: iconSize
    };
    const marker = new mapInstance.googleMaps.Marker(markerOption);

    const infoContent = getCustomInfoWindowContent(position);
    const infoWindow = new mapInstance.googleMaps.InfoWindow({ content: infoContent });

    marker.addListener('click', () => {
      if (mapInstance.currentOpenInfoWindow) {
        mapInstance.currentOpenInfoWindow.infoWindow.close();
      }
      infoWindow.open({
        anchor: marker,
        map: mapInstance.map,
        shouldFocus: true
      });
      mapInstance.map.panTo({ lat: point.lat, lng: point.lng });
      mapInstance.currentOpenInfoWindow = {
        marker: marker,
        infoWindow: infoWindow,
        point: { lat: point.lat, lng: point.lng },
        deviceImei: `marker_${position}`
      };
    });

    infoWindow.addListener('closeclick', () => {
      mapInstance.currentOpenInfoWindow = undefined;
    });

    return marker;
  }
}

function drawPath(mapInstance, points) {
  if (mapInstance.mapType === 'baidu') {
    const baiduPoints = points.map(point => new mapInstance.bMap.Point(point.lng, point.lat));
    const polyline = new mapInstance.bMap.Polyline(
      baiduPoints, { strokeColor: 'black', strokeWeight: 2, strokeOpacity: 0.5 }
    );
    mapInstance.map.addOverlay(polyline);
    const p = {
      id: guid(),
      polyline: polyline
    };
    mapInstance.polyList.push(p);
    mapInstance.currentPoly = p;
    return polyline;
  } else if (mapInstance.mapType === 'google') {
    const googlePoints = points.map(point => ({ lat: point.lat, lng: point.lng }));
    const polyline = new mapInstance.googleMaps.Polyline({
      path: googlePoints,
      strokeColor: 'black',
      strokeWeight: 2,
      strokeOpacity: 1
    });
    polyline.setMap(mapInstance.map);
    const p = {
      id: guid(),
      polyline: polyline
    };
    mapInstance.polyList.push(p);
    mapInstance.currentPoly = p;
    return polyline;
  }
}

function getCustomInfoWindowContent(position) {
  i18n.locale = window.sessionStorage.getItem('lang');
  let content = '<table>';
  if (position === 'start') {
    content += `<tr><td> ${i18n.t('computerLanguage.startPoint')} </td></tr>`;
  } else if (position === 'end') {
    content += `<tr><td> ${i18n.t('computerLanguage.endPoint')} </td></tr>`;
  }
  content += '</table>';
  return content;
}

// 结束 起点终点连线的绘制
// 显示隐藏轨迹点
// 显示标记点
export function showMarkers(mapInstance) {
  if (!mapInstance ||!mapInstance.markList) {
    console.error('Invalid map instance or no markers to show');
    return;
  }

  mapInstance.markList.forEach(marker => {
    if (mapInstance.mapType === 'baidu') {
      mapInstance.map.addOverlay(marker);
    } else if (mapInstance.mapType === 'google') {
      marker.setMap(mapInstance.map);
    }
  });
}

// 隐藏标记点
export function hideMarkers(mapInstance) {
  if (!mapInstance ||!mapInstance.markList) {
    console.error('Invalid map instance or no markers to hide');
    return;
  }

  mapInstance.markList.forEach(marker => {
    if (mapInstance.mapType === 'baidu') {
      mapInstance.map.removeOverlay(marker);
    } else if (mapInstance.mapType === 'google') {
      marker.setMap(null);
    }
  });
}